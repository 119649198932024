import { messageSuccess, messageError } from './messages'
function tratarErro400(erro, component) {
    let respostaErro = null;
    if (erro.response && erro.response.data && erro.response.data != '') {
        respostaErro = typeof(erro.response.data) == 'string' ? JSON.parse(erro.response.data) : erro.response.data;
        if (respostaErro.erros && respostaErro.erros.length > 0) {
            let errosGenericos = []
                .concat
                .apply(
                  [],
                  respostaErro.erros.filter(m =>
                    typeof m.mensagens != 'undefined' && m.mensagens != null && m.mensagens != '' && m.mensagens != []
                  ).map(m => m.mensagens)
                )
                .map(m => `<li>${m}</li>`)
            if (errosGenericos.length > 0) {
                let message = `Ocorreram os seguintes erros ao processar a requisição:<ul>${errosGenericos.join('')}</ul>`
                messageError(component, message)
            }

            let errosModel = respostaErro.erros.filter(m => m.campo != null)
            if (errosModel.length > 0) {
                errosModel.forEach(error => {
                    error.mensagens.forEach(message => {
                        component.errors.add({
                            field: error.campo,
                            msg: message
                        })
                    });
                });
            }
        } else {
            console.log('erro desconhecido', erro.response)
        }
    } else {
        console.log('erro desconhecido', erro)
    }
}

export function trataErro(erro, component) {
    if (erro && erro.response && erro.response.status) {
        switch (erro.response.status) {
            case 400:
                tratarErro400(erro, component)
                break;

            case 404:
                component.$router.push({name: 'erro.not.found'});
                break;

            case 403:
                component.$router.push({name: 'erro.forbidden'});
                break;

            default:
                component.$router.push({name: 'erro.server.error'})
        }
    } else {
        tratarErro400(erro, component)
    }
}

export function trataInsertSucesso(component, routeName, params = {}) {
    messageSuccess(component, 'Registro adicionado com sucesso.')
    component.$router.push({name: routeName, params});
}

export function trataUpdateSucesso(component, routeName, params = {}) {
    messageSuccess(component, 'Registro alterado com sucesso.')
    component.$router.push({name: routeName, params});
}

export function trataSucesso(component, mensagem, routeName, params = {}) {
    messageSuccess(component, mensagem)
    component.$router.push({name: routeName, params});
}