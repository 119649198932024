<template>
  <card-form>

    <div class="row mt-4">
      <f-input 
        name="nome" 
        :disabled="disabled"
        v-model="model.nome" 
        v-validate="{ required: true }" 
        data-vv-as="Nome"
        label="Nome"
        :modelErrors="errors"
        :cols="7"
      />
      <f-input 
        name="cnpj" 
        :disabled="disabled"
        v-model="model.cnpj" 
        v-mask="'##.###.###/####-##'"
        v-validate="{ required: true, cnpj: true }" 
        data-vv-as="CNPJ"
        label="CNPJ"
        :modelErrors="errors"
        :cols="3"
      />
      <f-selectsearch 
        label="UF do Hospital"
        name="estadoId"
        ref="estadoId"
        v-model="model.estadoId"
        :disabled="disabled"
        v-validate="{ required: true }" 
        data-vv-as="UF"
        :modelErrors="errors"
        :cols="2"
        :searchFunction="getAllEstado"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="selecionarEstado"
      />
      <f-switch
        name="ativo"
        :disabled="disabled"
        v-model="model.ativo"
        :cols="12"
        :label="model.ativo ? 'Ativo' : 'Inativo'"
      />

      <div class="row" v-if="!disabled">
        <div class="col-sm-6 d-flex justify-content-start">
          <h4 class="mt-3">Convênios</h4>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <label class="d-inline-flex align-items-center">
            Filtrar:
            <b-form-input
              v-model="convenios.filter.filtrar"
              type="search"
              class="form-control form-control-sm ms-2"
              @input="filtrarConvenios"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <ui-table
            id="convenio-convenio"
            :colunas="convenios.colunas"
            :items="conveniosFiltrados"
            :loading="convenios.loading"
            :force-script-ordenacao="true"
          >
            <template #actions="row">
              <b-button
                v-if="!disabled"
                variant="outline-danger"
                small
                title="remover"
                @click="removerConvenio(row.data)"
              >
                <i class="uil uil-multiply icon-size"></i>
              </b-button>
            </template>
            <template #hactions v-if="!disabled">
              <b-button
                variant="outline-success"
                small
                title="adicionar"
                @click="adicionarConvenio()"
              >
                <i class="uil uil-plus"></i>
              </b-button>
            </template>
            <template #renderCell(nome)="data">
              <div v-if="data.item.id > 0">{{ data.value }}</div>
              <f-selectsearch v-else
                :key="`convenio${data.item.id}`"
                name="selconv"
                placeholder="Digite para pesquisar o convênio"
                labelField="nome"
                :searchFunction="pesquisarConvenio"
                @select="selecionarConvenio"
                :modelErrors="errors"
                v-validate="'required'"
                data-vv-as="Convênio"
              />
            </template>
            <template #renderCell(codigo)="data">
              <div v-if="disabled">{{ data.value }}</div>
              <b-form-input v-else
                :key="`codigo${data.item.id}`"
                :name="`codigo${data.item.id}`"
                v-model="data.item.codigo"
                placeholder="Código do Hospital no Convênio"
                v-validate="{required: true}"
                data-vv-as="Código"
                :class="`form-control${(errors && errors.has(`codigo${data.item.id}`)) ? ' is-invalid' : ''}`"
              />
              <span class="text-danger" v-if="errors && errors.has(`codigo${data.item.id}`)">{{ errors.first(`codigo${data.item.id}`) }}</span>
            </template>
            <template #renderCell(valoracaoServico)="data">
              <div v-if="disabled">{{ data.value | toCurrency }}</div>
              <div class="row gap-1" v-else>
                <div class="row">
                  <label class="col-sm-4 col-form-label col-form-label-sm" :for="`valoracao${data.item.id}`">Internação</label>
                  <div class="col-sm-8">
                    <money
                      :id="`valoracao${data.item.id}`"
                      :key="`valoracao${data.item.id}`"
                      :name="`valoracao${data.item.id}`"
                      v-model="data.item.valoracaoServico"
                      :class="`text-end form-control form-control-sm ${(errors && errors.has(`valoracao${data.item.id}`)) ? ' is-invalid' : ''}`"
                      v-validate="'min_value:0.01'"
                      data-vv-as="Valoração Internação"
                      :data-vv-name="`valoracao${data.item.id}`"
                    />
                    <span class="text-danger" v-if="errors && errors.has(`valoracao${data.item.id}`)">{{ errors.first(`valoracao${data.item.id}`) }}</span>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-4 col-form-label col-form-label-sm" :for="`valoracaoSADT${data.item.id}`">SADT</label>
                  <div class="col-sm-8">
                    <money
                      :id="`valoracaoSADT${data.item.id}`"
                      :key="`valoracaoSADT${data.item.id}`"
                      :name="`valoracaoSADT${data.item.id}`"
                      v-model="data.item.valoracaoServicoSadt"
                      :class="`text-end form-control form-control-sm ${(errors && errors.has(`valoracao${data.item.id}`)) ? ' is-invalid' : ''}`"
                      v-validate="'min_value:0.01'"
                      data-vv-as="Valoração SADT"
                      :data-vv-name="`valoracaoSADT${data.item.id}`"
                    />
                    <span class="text-danger" v-if="errors && errors.has(`valoracao${data.item.id}`)">{{ errors.first(`valoracao${data.item.id}`) }}</span>
                  </div>
                </div>
              </div>
            </template>
          </ui-table>
        </div>
      </div>
      
    </div>

    <template #footer>
      <button v-if="!disabled" class="btn btn-success px-5" @click="submeter" type="button">{{ model.id == 0 ? 'Criar' : 'Alterar' }}</button>
      <button class="btn btn-info px-5" @click="() => {$router.push({name: 'sistema.hospital.lista'})}" type="button">Voltar</button>
    </template>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import { Money } from 'v-money'
import { Validator } from 'vee-validate';
import { getHospital, criarHospital, atualizarHospital, getAllConvenio, getAllEstado } from "@/services/hospital.service";
import { trataErro, trataInsertSucesso, trataUpdateSucesso } from "../../../../utils/tratar.retornos";
import validateCnpj from '../../../../utils/validator.cnpj'
import UiTable from '../../../../components/Ui/UiTable.vue';
import FSelectsearch from '@/components/Form/SelectSearch';

export default {

  components: {
    CardForm,
    UiTable,
    FSelectsearch,
    Money,
  },
  data() {
    return {
      getAllEstado,
      disabled: true,
      model: {
        id: 0,
        nome: '',
        ativo: true,
        cnpj: '',
        estadoId: null,
        convenios: [],
      },
      convenios: {
        filter: {
          filtrar: ''
        },
        colunas: [],
        paginacao: {
          pagina: 1,
        },
        loading: true
      }
    };
  },
  computed: {
    conveniosFiltrados() {
      return this.model.convenios.filter((convenio) => {
        return (
          convenio.nome.indexOf(this.convenios.filter.filtrar) != -1 ||
          convenio.codigo.indexOf(this.convenios.filter.filtrar) != -1
        );
      });
    }
  },
  watch: {
    disabled() {
      this.fixActionsSize();
    }
  },
  mounted() {
    let cols = [
      { key: "nome", label: "Convênio", sortable: true },
      { key: "codigo", label: "Código", sortable: false },
      { key: "valoracaoServico", label: "Valoração do serviço", sortable: false }
    ]
    
    this.disabled = !this.$route.meta.editavel;

    if (!this.disabled) {
      cols.push({ key: "action", label: "Ações", thClass: 'text-center', tdClass: 'text-center text-nowrap' })
    }

    this.convenios.colunas = cols

    this.fixActionsSize();
    if (this.$route.params.id && this.$route.params.id > 0) {
      getHospital(this.$route.params.id).then(({ data }) => {
        this.model = data.resultado;

        this.$refs.estadoId.setInitialValue(this.model.estadoId);
      })
      .catch((e) => 
        trataErro(e, this)
      )
      .finally(() => {
        this.convenios.loading = false;
      });
    } else {
      this.convenios.loading = false;
    }
    
    Validator.extend('cnpj', validateCnpj);
  },
  methods: {
    async submeter(event) {
      event.preventDefault()
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        if (this.model.id == 0) {
          criarHospital(this.model).then(() => {
            trataInsertSucesso(this, 'sistema.hospital.lista')
          })
          .catch((e) => trataErro(e, this))
        } else {
          atualizarHospital(this.model).then(() => {
            trataUpdateSucesso(this, 'sistema.hospital.lista')
          })
          .catch((e) => trataErro(e, this))
        }
      }
    },
    fixActionsSize() {
      let actionIdx = -1;
      for(let i = this.convenios.colunas.length - 1; i >= 0; i--) {
        if (this.convenios.colunas[i].key == 'action') {
          actionIdx = i;
          break;
        }
      }

      if (actionIdx != -1) {
        if(this.disabled) {
          this.convenios.colunas[actionIdx].thStyle = 'width: 45px;';
        } else {
          this.convenios.colunas[actionIdx].thStyle = 'width: 85px;';
        }
      }
    },
    adicionarConvenio() {
      for(let i = this.model.convenios.length - 1; i >= 0; i--) {
        if (!this.model.convenios[i].id) {
          this.model.convenios.splice(i, 1);
        }
      }

      this.model.convenios.unshift({ // Coloca no início da lista
        id: 0,
        nome: '',
        codigo: '',
        valoracaoServico: 0,
        valoracaoServicoSadt: 0
      });
    },
    filtrarConvenios() {
      this.convenios.paginacao.pagina = 1;
    },
    pesquisarConvenio(query) {
      const idsJaSelecionados = this.model.convenios.map((item) => item.id);

      return getAllConvenio({ filtrar: query }).then(response => {
        return { data: response.data.resultado.filter((item) => {
          return idsJaSelecionados.indexOf(item.id) == -1 && item.status; // Não exibir como opção convênios já selecionados
        }) }
      })
    },
    removerConvenio(convenio) {
      this.$swal.fire({
        title: `Remover registro do convenio ${convenio.nome} do convênio?`,
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
      }).then(result => {
        if (result.isConfirmed) {
          for(let i = 0, m = this.model.convenios.length; i < m; i++) {
            if (this.model.convenios[i].id == convenio.id) {
              this.model.convenios.splice(i, 1);
              break;
            }
          }
        }
      });
    },
    selecionarConvenio(convenio) {
      if (convenio && convenio.id > 0) {
        let newConvenioIdx = -1;
        for(let i = 0; i < this.model.convenios.length; i++) {
          if (!this.model.convenios[i].id) {
            newConvenioIdx = i;
            break;
          }
        }

        if (newConvenioIdx != -1) { // Seta o convênio procurado dentro da lista de convênios do hospital
          this.model.convenios[newConvenioIdx].id = convenio.id;
          this.model.convenios[newConvenioIdx].nome = convenio.nome;
        }
      }
    },
    selecionarEstado(estado) {
      this.model.estadoId = estado.id;
      this.$refs.estadoId.setInitialValue(this.model.estadoId);
    },
  },
};
</script>